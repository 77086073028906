import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducer from './reducers/reducer'
import { asyncSessionStorage } from 'redux-persist/storages'
import { persistStore, autoRehydrate } from 'redux-persist'

function promiseMiddleware () {
  return (next) => (action) => {
    const { promise, types, ...rest } = action
    if (!promise) {
      return next(action)
    }
    const [REQUEST, SUCCESS, FAILURE] = types
    next({ ...rest, type: REQUEST })
    return promise.then(
      (value) => next({ ...rest, value, type: SUCCESS }),
      (error) => next({ ...rest, error, type: FAILURE })
    )
  }
}

let store = null

export function configureStore (initialState) {
  store = createStore(reducer, initialState, compose(
    applyMiddleware(promiseMiddleware, thunk),
    autoRehydrate()
  ))
  persistStore(store, {
    storage: asyncSessionStorage,
    blacklist: ['config']
  })
  return store
}

export function getStore () {
  if (store === null) {
    return configureStore()
  } else {
    return store
  }
}
