import sessionApi from '../providers/user.js'

function subscribeUser (donor, recaptchaToken, extraSegments, extraSegmentIds) {
  return function (dispatch) {
    return sessionApi.subscribeUser(donor, recaptchaToken, extraSegments, extraSegmentIds)
      .then()
      .catch((error) => {
        throw (error)
      })
  }
}

export default {
  subscribeUser
}
