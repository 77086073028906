export const INIT_USER = 'INIT_USER'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const RESET_SUCCESS = 'RESET_SUCCESS'
export const UPDATE_USER = 'UPDATE_USER'
export const LOGIN_WITH_EMAIL = 'LOGIN_WITH_EMAIL'
export const LOGIN_WITH_FACEBOOK = 'LOGIN_WITH_FACEBOOK'
export const LOGIN_WITH_GOOGLE = 'LOGIN_WITH_GOOGLE'
export const LOGIN_WITH_TWITTER = 'LOGIN_WITH_TWITTER'
export const LOGOUT = 'LOGOUT'
export const DONATE_SUCCESS = 'DONATE_SUCCESS'
export const DONATE_RESET = 'DONATE_RESET'
