import React from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { getStore } from '../common/store'

class Unsubscribe extends React.Component {

  componentDidMount () {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const donorId = urlParams.get('donorId');
    const postId = urlParams.get('postId');
    const charityId = getStore().getState().config.charityId;
    const notificationServiceUrl = getStore().getState().config.notificationServiceUrl;

    let url = new URL(`${notificationServiceUrl}/email/sendgrid/unsubscribe`);

    let params = { email: email, donorId: donorId, charityId: charityId, postId: postId };
    url.search = new URLSearchParams(params).toString();

    fetch(url, {
      method: 'GET',
      mode: 'no-cors'
    })
  }

  render () {
    return (
      <div className='orange-container'>
        <Grid fluid className='unsubscribe'>
          <Row>
            <Col mdOffset={3} md={6} xs={12}>
              <h2>{this.props.config.stringValues.site.unsubscribe.title}</h2>
              <p>
                {this.props.config.stringValues.site.unsubscribe.successful}
              </p>
              <div className='unsubscribe-img' ></div>
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    config: state.config
  }
}

export default connect(mapStateToProps)(Unsubscribe)
