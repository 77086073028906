import React from 'react'

export class GoogleConversionPixel extends React.Component {
  static loadPixel (amount, onload) {
    const imgTag = document.createElement('img')
    imgTag.src = `//www.googleadservices.com/pagead/conversion/830410209/?value=${amount}&currency_code=USD&label=Upb-CN7ngncQ4Zv8iwM&guid=ON&script=0`
    imgTag.height = 1
    imgTag.width = 1
    imgTag.style['border-style'] = 'none'
    imgTag.alt = ''

    document.body.appendChild(imgTag)
  }
}

export class GoogleTrackingPixel {
  static loadPixel () {
    const imgTag = document.createElement('img')
    imgTag.src = '//googleads.g.doubleclick.net/pagead/viewthroughconversion/830410209/?guid=ON&script=0"'
    imgTag.height = 1
    imgTag.width = 1
    imgTag.style['border-style'] = 'none'
    imgTag.alt = ''

    document.body.appendChild(imgTag)
  }
}

export class FacebookTrackingPixel {
  static loadPixel (eventCode, amount) {
    const imgTag = document.createElement('img')
    imgTag.src = `https://www.facebook.com/tr?id=136909883701762&ev=${eventCode}&noscript=1`
    if (amount) {
      imgTag.src += `&amp;cd[value]=${amount}&amp;cd[currency]=USD`
    }

    imgTag.height = 1
    imgTag.width = 1
    imgTag.style['display'] = 'none'
    imgTag.alt = ''

    document.body.appendChild(imgTag)
  }
}
