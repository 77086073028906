import React from 'react';
import GiveButton from '../components/give-button'
import Linkify from 'react-linkify'
import ReactHtmlParser from 'react-html-parser'
import { replaceAll } from '../common/utils'

export default function embeddedPost({ postInfo, charityConfig }) {
  let {media, title, content, styledContent} = postInfo;
  let mediaKey = Object.keys(media)[0]
  let mediaType = 'image';

  Object.keys(media).forEach(key => {
    if (media[key]['type'] === 'video') {
      mediaKey = key;
    }
  });

  let mediaUrl = media[mediaKey]['mediaUrl'];

  if (media[mediaKey]['type'] === 'video') {
    mediaType = 'video';
    mediaUrl = '//player.vimeo.com/video/'+ mediaUrl.substr(8, mediaUrl.length);
    if (mediaUrl.includes("?h=")) {
      mediaUrl += "&";
    } else {
      mediaUrl += "?";
    }
    mediaUrl += 'title=0&byline=0&portrait=0&badge=0&muted=0&pip=0';
  } else {
    mediaType = 'image';
  }

  const showViewSparkDescription = false;

  return (
    <div className='post-embedded'>
      <div className={'mediaContainer'}>
        {mediaType === 'image' && 
          <>
            <img
              className={'mediaImage'}
              src={mediaUrl}
              alt="ViewSpark image"
            />
          </>
        }
        {mediaType === 'video' &&
          <>
            <iframe
              className={'mediaFrame'}
              src={mediaUrl}
              frameBorder="0"
              allowFullScreen
            />
          </>
        }
      </div>
      <div className={'responsiveTitle'}>
        <span>{title}</span>
      </div>
      { showViewSparkDescription && (
        <div className={'responsiveDescription'}>
          <Linkify>
            {styledContent ? ReactHtmlParser(replaceAll(styledContent, '<p></p>', '<br/>')) : content}
          </Linkify>
        </div>
      )}
      <GiveButton postInfo={postInfo} charityConfig={charityConfig}></GiveButton>
    </div>
  );
}
