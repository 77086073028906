import React, { Fragment } from 'react'
import { Route, Link, Switch, withRouter, Redirect } from 'react-router-dom'
import Menu from './menu'
import { campaignAssetUrl, valueOrUndefined } from '../donate/common'
import { connect } from 'react-redux'
import { Img } from 'react-image'

import { Grid, Row, Col, Navbar } from 'react-bootstrap'
import { Style } from 'radium'
import loadable from '@loadable/component'
import Unsubscribe from './unsubscribe'
import EmbeddedPost from './embedded-post'
import get from 'lodash/get'

const PostDetail = loadable(() => import('./post-detail'))
const Subscribe  = loadable(() => import('./subscribe'))
const NewsFeed = loadable(() => import('./news-feed'))
const Donate = loadable(() => import('../donate'))
const NotFound = loadable(() => import('./notFound'))

const mapStateToProps = (state) => ({
  donateSuccess: state.donate.donateSuccess,
  config: state.config,
})

function SmartDonate ({ embed, config, target, donorList }) {
  // Check if we have mappings
  let campaign = 'default'
  if (config.stringValues.donateMappings && Array.isArray(config.stringValues.donateMappings)) {
    // Will use the first mapping that matches, so order matters.
    for (const mapping of config.stringValues.donateMappings) {
      const targetMatch = mapping.target === target
      const embedMatch = !mapping.embedMode || mapping.embedMode === 'both' || (embed && mapping.embedMode === 'embedOnly') || (!embed && mapping.embedMode === 'pageOnly')
      const scheduleMatch = !mapping.schedule || (
        (!mapping.schedule.from || mapping.schedule.from < config.renderEpoch) &&
        (!mapping.schedule.to || mapping.schedule.to > config.renderEpoch)
      )

      if (targetMatch && embedMatch && scheduleMatch) {
        campaign = mapping.campaignId
        break
      }
    }
  } else {
    campaign = target
  }
  const loader = (uri) => campaignAssetUrl(uri, campaign)
  const campaignConfig = config.stringValues.donate && config.stringValues.donate[campaign]
  if (campaignConfig) {
    return (
      <div>
        <Donate enableGtm={!!config.gtmId} assetLoader={loader} donorList={donorList} stripeKey={config.paymentKey} campaignConfig={campaignConfig}
          campaign={campaign} />
      </div>
    )
  } else {
    return (
      <div>
        <NotFound customDonationUrl={config.customDonationUrl} />
      </div>
    )
  }
}

function SmartSubscribe ({ config, target, props, embedMode=false }) {
  let customTarget = 'default'

  if (target && config.stringValues.site.subcribeExtraFields && config.stringValues.site.subcribeExtraFields[target]) {
    customTarget = target
  }

  const subcribeExtraFields = config.stringValues.site.subcribeExtraFields && config.stringValues.site.subcribeExtraFields[customTarget]

  if (subcribeExtraFields) {
    return (
      <div>
        <Subscribe embedMode={embedMode} history={props.history} config={config} customSubscribe={subcribeExtraFields} target={customTarget} props={props} />
      </div>
    )
  } else {
    return (
      <div>
        <Subscribe embedMode={embedMode} history={props.history} config={config} props={props}/>
      </div>
    )
  }
}

class BaseHeader extends React.Component {
  render () {
    const path = this.props.location.pathname
    let logoWidth = valueOrUndefined(this.props.config.stringValues.site, 'header.width')
    let logoHeight = valueOrUndefined(this.props.config.stringValues.site, 'header.height')
    return (
      <div
        className={(path === '/donate' || path === '/donateMonthly' || path.substring(0, 7) === '/donate') ? 'donate-page' : ''}>
        <Navbar className={'headerV2'}>
          <Navbar.Header>

            {this.props.config.stringValues.site && this.props.config.stringValues.site.custom === 'BF' &&
              <Navbar.Brand>
                <a href='https://brightfaces.org' className='logo-web' />
              </Navbar.Brand>
            }
            {this.props.config.stringValues.site && this.props.config.stringValues.site.custom !== 'BF' && (
              this.props.config.isPrime || this.props.config.enableHeaderTitle) &&
              <Fragment>
                {this.props.config.headerRedirectUrl &&
                  <Navbar.Brand className={'prime-navbar'}>
                    <a href={this.props.config.headerRedirectUrl}>
                      <Img src={this.props.config.logoDonate} container={
                        children => {
                          return <div className={'logo-web-prime'}>
                            <Style
                              scopeSelector='.logo-web-prime'
                              rules={{
                                img: {
                                  width: !logoWidth ? '180px' : logoWidth,
                                  height: !logoHeight ? 'auto' : logoHeight
                                }
                              }}
                            />
                            {children}
                          </div>
                        }
                      } />
                      {this.props.config.stringValues.site.header && this.props.config.stringValues.site.header.title &&
                      <span className={'charity-name ' + (this.props.config.stringValues.site.header.title.length <= 32 ? 'padding' : '')}>
                          {this.props.config.stringValues.site.header.title}
                        </span>
                      }
                    </a>
                  </Navbar.Brand>
                }
                {!this.props.config.headerRedirectUrl &&
                  <Navbar.Brand className={'prime-navbar'}>
                    <Link to='/'>
                      <Img src={this.props.config.logoDonate} container={
                        children => {
                          return <div className={'logo-web-prime'}>
                            <Style
                              scopeSelector='.logo-web-prime'
                              rules={{
                                img: {
                                  width: !logoWidth ? '180px' : logoWidth,
                                  height: !logoHeight ? 'auto' : logoHeight
                                }
                              }}
                            />
                            {children}
                          </div>
                        }
                      } />
                      {this.props.config.stringValues.site.header && this.props.config.stringValues.site.header.title &&
                        <span className={'charity-name ' + (this.props.config.stringValues.site.header.title.length <= 32 ? 'padding' : '')}>
                          {this.props.config.stringValues.site.header.title}
                        </span>
                      }
                    </Link>
                  </Navbar.Brand>
                }
              </Fragment>
            }
            {this.props.config.stringValues.site && this.props.config.stringValues.site.custom !== 'BF' && (!this.props.config.isPrime && !this.props.config.enableHeaderTitle) &&
              <Fragment>
                {this.props.config.headerRedirectUrl &&
                  <Navbar.Brand>
                    <a href={this.props.config.headerRedirectUrl} className='logo-web'
                     style={{
                      width: !logoWidth ? '180px' : logoWidth,
                      height: !logoHeight ? '58px' : logoHeight
                    }}/>
                  </Navbar.Brand>
                }
                {!this.props.config.headerRedirectUrl &&
                  <Navbar.Brand>
                    <Link to='/' className='logo-web' />
                  </Navbar.Brand>
                }
              </Fragment>
            }

            {this.props.config.stringValues.site && (!this.props.config.stringValues.site.disableMenu) &&
              <Navbar.Toggle />
            }
          </Navbar.Header>
          {
            this.props.config.stringValues.site && (!this.props.config.stringValues.site.disableMenu) &&
            <Navbar.Collapse>
              <Menu />
            </Navbar.Collapse>
          }
        </Navbar>
      </div>
    )
  }
}

const ConnectedHeader = connect(mapStateToProps)(BaseHeader)
const Header = withRouter(props => <ConnectedHeader {...props} />)

class Page extends React.Component {
  shouldComponentUpdate (nextProps) {
    return nextProps.donateSuccess === this.props.donateSuccess
  }

  getEmbeddedPost() {
    return this.props.config.latestEmbeddedPost;
  }

  render () {
    const enableEmbeddedPost = get(this.props.config, 'enableEmbeddedPost', false);
    
    return (
      <Switch>
        { enableEmbeddedPost && !this.getEmbeddedPost() && (
          <Route path='/embeddedPost' render={() => <Redirect to='/' />} />
        )}
        { enableEmbeddedPost && (
          <Route
            path='/embeddedPost' exact
            render={() => (
              <>
                <div className='body-no-header'>
                  <EmbeddedPost postInfo={this.getEmbeddedPost()} charityConfig={this.props.config} />
                </div>
              </>
            )}
          />
        )}
        { !enableEmbeddedPost && (<Route path='/embeddedPost' render={() => <Redirect to='/' />} />) }
        <Route
          path='/subscribeEmbed' exact
          render={(props) => (
            <>
              <div className='body-no-header'>
                <SmartSubscribe embedMode={true} props={props} config={this.props.config} target='default' />
              </div>
            </>
          )}
        />
        <Route
          path='/subscribeEmbed/:target' exact
          render={(props) => (
            <>
              <div className='body-no-header'>
                <SmartSubscribe embedMode={true} props={props} config={this.props.config} target={props.match.params.target} />
              </div>
            </>
          )}
        />
        <Route
          path='/donateEmbed' exact
          render={() => (
            <>
              <div className='body-no-header'>
                <SmartDonate embed config={this.props.config} target='default' />
              </div>
              <Footer/>
            </>
          )}
        />
        <Route
          path='/donateEmbed/:target' exact
          render={(props) => (
            <>
              <div className='body-no-header'>
                <SmartDonate embed config={this.props.config} target={props.match.params.target} donorList='Online' />
              </div>
              <Footer/>
            </>
          )}
        />
        {this.props.config.stringValues.site && this.props.config.stringValues.site.custom === 'BF' &&
          <Route path='/donateEmbedM' render={() => <Redirect to='/donateEmbed/monthly' />} />
        }
        <Route path='/'>
          <div className='page'>
            <Header />
            <Body />
            <Footer />
            {this.props.children}
          </div>
        </Route>
      </Switch>
    )
  }
}

const ConnectedPage = connect(mapStateToProps)(Page)
export default withRouter(props => <ConnectedPage {...props} />)

class BaseBody extends React.Component {
  shouldComponentUpdate (nextProps) {
    return nextProps.donateSuccess === this.props.donateSuccess
  }

  getRoutes () {
    if (this.props.config.onlySubscribePage) {
      return <Switch>
        <Route path='/' exact render={(props) => <SmartSubscribe config={this.props.config} props={props} />} />
        <Route path='/about' render={() => <Redirect to='/' />} />
        <Route path='/news-feed' render={() => <Redirect to='/' />} />
        <Route path='/post' render={() => <Redirect to='/' />} />
        <Route path='/unsubscribe' component={Unsubscribe} />
        <Route path='/donate' exact render={() => <Redirect to='/' />} />
        <Route path='/donate/:target' exact render={() => <Redirect to='/' />} />
        <Route path='/subscribe' exact render={() => <Redirect to='/' />} />
        <Route path='/subscribe/:target'
          exact
          render={(props) =>
            <SmartSubscribe config={this.props.config} target={props.match.params.target} props={props} />
          }
        />

        <Route path='*' component={NotFound} />
      </Switch>
    } else if (!this.props.config.hasGlobalPosts && !this.props.config.disableSubscribe) {
      return <Switch>
        <Route path='/' exact render={(props) => <SmartSubscribe config={this.props.config} props={props} />} />
        <Route path='/subscribe' exact render={(props) => <SmartSubscribe props={props} config={this.props.config} />} />
        <Route path='/subscribe/:target' exact render={(props) => <SmartSubscribe props={props} config={this.props.config} target={props.match.params.target} />} />
        <Route
          path='/donate' exact
          render={() => <SmartDonate config={this.props.config} target='default' />}
        />
        <Route
          path='/donate/:target' exact
          render={(props) => <SmartDonate config={this.props.config} target={props.match.params.target} />}
        />
        <Route path='/post' component={PostDetail} />
        <Route path='/unsubscribe' component={Unsubscribe} />
        <Route path='/news-feed' render={() => <Redirect to='/' />} />
        <Route path='*' component={NotFound} />
      </Switch>
    } else if (!this.props.config.hasGlobalPosts && this.props.config.disableSubscribe) {
      return (
        <Switch>
          <Route exact path='/' render={() => <SmartDonate config={this.props.config} target='default' />} />
          <Route
            path='/donate' exact
            render={() => <SmartDonate config={this.props.config} target='default' />}
          />
          <Route
            path='/donate/:target' exact
            render={(props) => <SmartDonate config={this.props.config} target={props.match.params.target} />}
          />
          <Route path='/post' component={PostDetail} />
          <Route path='/unsubscribe' component={Unsubscribe} />
          <Route path='/news-feed' render={() => <Redirect to='/' />} />
          <Route path='*' component={NotFound} />
        </Switch>
      )
    } else if (this.props.config.hasGlobalPosts && this.props.config.disableSubscribe) {
      return (
        <Switch>
          <Route exact path='/' component={NewsFeed} />
          <Route
            path='/donate' exact
            render={() => <SmartDonate config={this.props.config} target='default' />}
          />
          <Route
            path='/donate/:target' exact
            render={(props) => <SmartDonate config={this.props.config} target={props.match.params.target} />}
          />
          <Route path='/post' component={PostDetail} />
          <Route path='/unsubscribe' component={Unsubscribe} />
          <Route path='/news-feed' render={() => <Redirect to='/' />} />
          <Route path='*' component={NotFound} />
        </Switch>
      )
    } else {
      return (
        <Switch>
          <Route exact path='/' component={NewsFeed} />
          <Route path='/subscribe' exact render={(props) => <SmartSubscribe props={props} config={this.props.config} />} />
          <Route path='/subscribe/:target'
            exact
            render={(props) =>
              <SmartSubscribe props={props} config={this.props.config} target={props.match.params.target} />
            }
          />
          <Route
            path='/donate' exact
            render={() => <SmartDonate config={this.props.config} target='default' />}
          />
          <Route
            path='/donate/:target' exact
            render={(props) => <SmartDonate config={this.props.config} target={props.match.params.target} />}
          />
          <Route path='/post' component={PostDetail} />
          <Route path='/unsubscribe' component={Unsubscribe} />
          <Route path='/news-feed' render={() => <Redirect to='/' />} />
          <Route path='*' component={NotFound} />
        </Switch>
      )
    }
  }

  render() {
    return (
      <div className='body'>
        {this.getRoutes()}
      </div>
    )
  }
}

const ConnectedBody = connect(mapStateToProps)(BaseBody)
const Body = withRouter(props => <ConnectedBody {...props} />)

class BaseFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    this.setState({loading: false})
  }

  render() {
    if(this.state.loading) return null;
    const path = this.props.location.pathname
    return <div
      className={(path === '/donate' || path === '/donateMonthly' || path.substring(0, 7) === '/donate') ? 'donate-page' : ''}>
      <div className='footerV2'>
        <Grid fluid>
          <Row>
            <Col xs={12} sm={8} md={8} className='copyright vcenter'>
              {this.props.config.stringValues.site.footer.firstPart ?
                this.props.config.stringValues.site.footer.firstPart.replace("YEAR", new Date().getFullYear().toString()) :
                ""
              }
              <span className='version'>
                {this.props.config.stringValues.site.footer.version ?
                  this.props.config.stringValues.site.footer.version.replace("YEAR", new Date().getFullYear().toString()) : ""}
              </span>
              <br />
              {this.props.config.stringValues.site.footer.secondPart ?
                this.props.config.stringValues.site.footer.secondPart.replace("YEAR", new Date().getFullYear().toString()) : ""}
              <br />
              <span>
                {this.props.config.stringValues.site.footer.privacyPolicyUrl ?
                  <a href={this.props.config.stringValues.site.footer.privacyPolicyUrl} target="_blank" style={{fontWeight: "bolder"}}>Privacy Policy</a> : ""
                }
              </span>
            </Col>
            <Col xs={12} sm={4} md={4} className='logo vcenter'>
              <a
                href='http://viewspark.org/'
                className='logo-viewspark'
                target='_blank' />
            </Col>
          </Row>
        </Grid>
      </div>
    </div>
  }
}

const ConnectedFooter = connect(mapStateToProps)(BaseFooter)
const Footer = withRouter(props => <ConnectedFooter {...props} />)
