import React, { Fragment } from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { getUrlParams, redirectWithConfirm } from '../common/utils.js'

export default function giveButton({ postInfo, charityConfig }) {
  const giveInstructions = charityConfig.giveInstructions;
  const enableInstructionsPopUp = charityConfig.enableInstructionsPopUp;
  let giveNowLink = '/donate' + getUrlParams();
  const showGiveButton = !charityConfig.disableGive && (postInfo && postInfo.postType !== "informative");

  return (
    <>
      <Col xs={12} className={showGiveButton ? 'actions' : 'noGiveActions'}>
        {showGiveButton && (!charityConfig.customDonationUrl && !postInfo.giveButtonUrl) &&
        <Link to={giveNowLink} target={postInfo.isEmbeddedPost ? '_blank' : ''} className='give-now-button-details'>{ get(postInfo, 'giveButtonLabel' ,
        get(charityConfig.stringValues, 'site.buttons.postDetail', 'Give Now')) }</Link>
        }
        {showGiveButton && (charityConfig.customDonationUrl || postInfo.giveButtonUrl) && giveInstructions && enableInstructionsPopUp &&
        <a href={'#'} target={postInfo.isEmbeddedPost ? '_blank' : ''} className={'give-now-button-details'} onClick={() => {
        redirectWithConfirm(charityConfig.customDonationUrl, giveInstructions)
        }}>
        { get(postInfo, 'giveButtonLabel' , get(charityConfig.stringValues, 'site.buttons.postDetail', 'Give Now')) }
        </a>
        }
        {showGiveButton && (charityConfig.customDonationUrl || postInfo.giveButtonUrl) && !(giveInstructions && enableInstructionsPopUp) &&
        <a href={postInfo.giveButtonUrl ? postInfo.giveButtonUrl :
        charityConfig.customDonationUrl } target={postInfo.isEmbeddedPost ? '_blank' : ''} className={'give-now-button-details'}>
        { get(postInfo, 'giveButtonLabel' , get(charityConfig.stringValues, 'site.buttons.postDetail', 'Give Now')) }
        </a>
        }
        <a className='clear' />
      </Col>
      <Col xs={12} className={'description-details' + (charityConfig.disableGive ? ' disableGiveMargin' : '')}>
        {!postInfo.isEmbeddedPost && giveInstructions &&
        <Fragment>
            <p id={'giving-inst'}>
            Giving Instructions:
            </p>
            <p id={'giving-inst-p'}>
            {giveInstructions}
            </p>
        </Fragment>
        }
      </Col>
    </>
  );
}
