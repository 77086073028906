import {
  DONATE_SUCCESS,
  DONATE_RESET
} from './actions'

export default function (state = { donateSuccess: false }, action) {
  switch (action.type) {
    case DONATE_SUCCESS:
      return Object.assign({}, state, { donateSuccess: true })
    case DONATE_RESET:
      return Object.assign({}, state, { donateSuccess: false })
    default:
      return state
  }
}
