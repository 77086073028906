import React from 'react'
import { hydrate } from 'react-dom'
import { Provider, connect } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'
import Page from './layout.jsx'
import ReactGA from 'react-ga'
import { GoogleTrackingPixel } from './conversion.jsx'
import queryString from 'query-string'
import { configureStore } from '../common/store'
import { StyleRoot } from 'radium'

class PageLogger extends React.Component {
  componentDidMount () {
    const trackingId = this.props.config.trackingId

    if (trackingId) {
      ReactGA.initialize(trackingId)
    }
    if (trackingId) {
      console.info('Initializing GA with', trackingId)
      ReactGA.plugin.require('ec')
      ReactGA.set({page: window.location.pathname + window.location.search, currencyCode: 'USD'})
      if (!window.location.pathname.startsWith('/donate')) {
        ReactGA.pageview(window.location.pathname + window.location.search)
      }
    }

    const parsed = queryString.parse(window.location.search)
    if (this.props.config.stringValues.bfTracking && parsed.google) {
      // Load the pixel
      GoogleTrackingPixel.loadPixel()
    }
  }

  render () {
    return <div />
  }
}

const mapStateToProps = state => {
  return {
    config: state.config
  }
}

let logger = connect(mapStateToProps)(PageLogger)
const store = configureStore(window.INITIAL_STATE)

hydrate(
  <Provider store={store}>
    <StyleRoot>
      <BrowserRouter>
        <Page>
          <Route component={logger} />
        </Page>
      </BrowserRouter>
    </StyleRoot>
  </Provider>,
  document.getElementById('container')
)
