import fetch from 'isomorphic-fetch'
import { getStore } from './store'
import { isEmpty, trim } from 'lodash'

export const subscribe = function (donor, recaptchaToken, extraSegments, extraSegmentIds) {
  const adminServiceUrl = getStore().getState().config.adminServiceUrl
  const charityId = getStore().getState().config.charityId
  let subscribeUrl = `${adminServiceUrl}/api/v1/${charityId}/donor/subscribe?recaptchaToken=${recaptchaToken}`
  if (extraSegments !== null) {
    subscribeUrl += `&extraSegments=${encodeURIComponent(extraSegments)}`
  }

  if (!isEmpty(trim(extraSegmentIds))) {
    subscribeUrl += `&extraSegmentIds=${encodeURIComponent(extraSegmentIds)}`
  }
  return fetch(subscribeUrl, {
    method: 'POST',
    body: JSON.stringify({
      email: donor.email,
      firstName: donor.firstName,
      lastName: donor.lastName,
      phoneNumber: donor.phoneNumber,
      extraFields: donor.extraFields
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      if (response.status === 200) {
        return response.text()
      } else if (response.status === 400) {
        return response.json()
      } else {
        throw new Error('Failed to call subscribe donor')
      }
    })
    .then(text => {
      if (text.message) {
        throw new Error(text.message)
      } else {
        return JSON.parse(text)
      }
    })
    .then(data => data.message)
}
