import parseQuery from '../views/parser.js'
import { confirmAlert } from 'react-confirm-alert'
import React from 'react'

export const replaceAll = (text, pattern, replace) => {
  return text.split(pattern).join(replace)
}

const PARAMS = ['mot', 'QRCode']

const isStorageDefined = (field) => {
  return (
    window.sessionStorage &&
    window.sessionStorage.getItem(field) !== null &&
    window.sessionStorage.getItem(field) !== 'undefined'
  )
}

export const getUrlParams = () => {
  let params = ''
  let source = null
  let medium = null
  if (typeof window !== 'undefined') {
    try {
      if (isStorageDefined('utm_source') && isStorageDefined('utm_medium')) {
        source = window.sessionStorage.getItem('utm_source')
        medium = window.sessionStorage.getItem('utm_medium')
      } else {
        source = parseQuery.getQueryVariable('utm_source')
        medium = parseQuery.getQueryVariable('utm_medium')
      }
    } catch (e) {
      console.log('Cookies')
    }
  }
  if (
    typeof source !== 'undefined' &&
    typeof medium !== 'undefined' &&
    source !== 'undefined' &&
    medium !== 'undefined' &&
    source !== null &&
    medium !== null
  ) {
    params = `?utm_source=${source}&utm_medium=${medium}`
  } else {
    params = '?utm_source=viewspark&utm_medium=web'
  }

  if (typeof window !== 'undefined') {
    PARAMS.forEach((param) => {
      let val = null
      try {
        if (isStorageDefined(param)) {
          val = window.sessionStorage.getItem(param)
        } else {
          val = parseQuery.getQueryVariable(param)
        }
      } catch (e) {
        console.log('Cookies')
      }
      if (typeof val !== 'undefined' && val !== 'undefined' && val !== null) {
        params += `&${param}=${val}`
      }
    })
  }
  return params
}

export const getMedium = () => {
  if (typeof window !== 'undefined') {
    try {
      if (window.sessionStorage) {
        const sessionStorageMedium = window.sessionStorage.getItem('utm_medium')
        return typeof sessionStorageMedium !== 'undefined' &&
          sessionStorageMedium !== 'undefined'
          ? sessionStorageMedium
          : 'web'
      }
      return parseQuery.getQueryVariable('utm_medium')
        ? parseQuery.getQueryVariable('utm_medium')
        : 'web'
    } catch (e) {}
  } else {
    return 'web'
  }
}

export const getSource = () => {
  if (typeof window !== 'undefined') {
    try {
      if (window.sessionStorage) {
        const sessionStorageSource = window.sessionStorage.getItem('utm_source')
        return typeof sessionStorageSource !== 'undefined' &&
          sessionStorageSource !== 'undefined'
          ? sessionStorageSource
          : 'viewspark'
      }
      return parseQuery.getQueryVariable('utm_source')
        ? parseQuery.getQueryVariable('utm_source')
        : 'viewspark'
    } catch (e) {}
  } else {
    return 'viewspark'
  }
}

export const saveUrlParams = () => {
  let source = null
  let medium = null
  try {
    if (!isStorageDefined('utm_source') && !isStorageDefined('utm_medium')) {
      source = parseQuery.getQueryVariable('utm_source')
      medium = parseQuery.getQueryVariable('utm_medium')
      window.sessionStorage.setItem('utm_source', source)
      window.sessionStorage.setItem('utm_medium', medium)
    }

    PARAMS.forEach((param) => {
      if (!isStorageDefined(param)) {
        const val = parseQuery.getQueryVariable(param)
        if (val) 
          window.sessionStorage.setItem(param, val)
      }
    })
  } catch (e) {
    console.log('error: ' + e)
  }
}

const redirectFunction = (url) => {
  if (window) {
    window.location.replace(url)
  }
}

export const redirectWithConfirm = (url, instructions) => {
  setTimeout(() => {
    redirectFunction(url)
  }, 3000)
  confirmAlert({
    customUI: (_) => {
      return (
        <div className="custom-ui-alert">
          <h2>Giving Instructions</h2>
          <h4>{instructions}</h4>
        </div>
      )
    },
  })
}
