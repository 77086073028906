import React from 'react'
import ReactMarkdown from 'react-markdown'
import get from 'lodash/get'
import { getStore } from '../common/store'

export function markdown (text) {
  return <ReactMarkdown
    escapeHtml={false}
    disallowedTypes={['thematicBreak', 'blockquote', 'delete', 'image', 'linkReference', 'imageReference',
      'table', 'tableHead', 'tableBody', 'tableRow', 'tableCell', 'definition', 'inlineCode', 'code']}
    unwrapDisallowed
    source={text} />
}

export function campaignAssetUrl (uri, campaignId) {
  const state = getStore().getState()
  return `${state.config.googleStorage.completeUrl}/${state.config.bucketId}/campaigns/${campaignId}/${uri}`
}

export function assetUrl (uri) {
  const state = getStore().getState()
  return `${state.config.googleStorage.completeUrl}/${state.config.bucketId}/sharedAssets/${uri}`
}

export function valueOrUndefined (obj, path, transform) {
  const value = get(obj, path)
  return value ? (transform ? transform(value) : value) : undefined
}

export function valueOrUndefinedBool (obj, path, transform) {
  const value = get(obj, path)
  return (value || value === false) ? (transform ? transform(value) : value) : undefined
}

export function calculateAmountWithCharge (amount) {
  return ((amount + 0.30) / 0.961).toFixed(2)
}

export function printNumber (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function timeout (interval) {
  return new Promise(resolve => {
    setTimeout(() => resolve(), interval)
  })
}
