import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import userActions from '../common/actions/user.js'
import { Nav, NavItem, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { getUrlParams, redirectWithConfirm, saveUrlParams } from '../common/utils.js'
import get from 'lodash/get'
import { Style } from 'radium'

class Menu extends React.Component {
  componentDidMount () {
    saveUrlParams()
  }

  render () {
    let giveNowLink = '/donate' + getUrlParams()
    const hideNavBar = get(this.props.config.stringValues, 'site.hideNavBar', false);

    if (this.props.config.onlySubscribePage || hideNavBar) {
      return <Nav pullRight />
    }
    const borderRadiusStyle = get(this.props.config.stringValues, 'site.buttons.borderRadius', '9px')
    return (
      <Nav pullRight>
        <Style
          scopeSelector='.give-now-button-nav'
          rules={{
            borderRadius: borderRadiusStyle
          }}
        />
        {!this.props.config.customDonationUrl && !this.props.config.disableGive &&
          <Fragment>
            <LinkContainer to={giveNowLink} activeClassName="">
              <NavItem>
                <Button className={'give-now-button-nav menuItem hidden-xs'}>
                  { get(this.props.config.stringValues, 'site.buttons.menu', 'Give') }
                </Button>
              </NavItem>
            </LinkContainer>
            <LinkContainer to={giveNowLink} activeClassName="">
              <NavItem className='menuItem hidden-sm hidden-md hidden-lg'>
                { get(this.props.config.stringValues, 'site.buttons.menu', 'Give') }
              </NavItem>
            </LinkContainer>
          </Fragment>
        }
        {this.props.config.customDonationUrl && !this.props.config.disableGive && !(this.props.config.giveInstructions && this.props.config.enableInstructionsPopUp) &&
          <Fragment>
            <NavItem
              href={this.props.config.customDonationUrl}>
              <Button className={'give-now-button-nav menuItem hidden-xs'}>
                { get(this.props.config.stringValues, 'site.buttons.menu', 'Give') }
              </Button>
            </NavItem>
            <NavItem className='menuItem hidden-sm hidden-md hidden-lg' href={this.props.config.customDonationUrl}>{ get(this.props.config.stringValues, 'site.buttons.menu', 'Give') }</NavItem>
          </Fragment>
        }
        {this.props.config.customDonationUrl && !this.props.config.disableGive && this.props.config.giveInstructions && this.props.config.enableInstructionsPopUp &&
        <Fragment>
          <NavItem onClick={() => {
            redirectWithConfirm(this.props.config.customDonationUrl, this.props.config.giveInstructions)
          }}>
            <Button className={'give-now-button-nav menuItem hidden-xs'}>
              { get(this.props.config.stringValues, 'site.buttons.menu', 'Give') }
            </Button>
          </NavItem>
          <NavItem className='menuItem hidden-sm hidden-md hidden-lg' onClick={() => {
            redirectWithConfirm(this.props.config.customDonationUrl, this.props.config.giveInstructions)
          }}>{ get(this.props.config.stringValues, 'site.buttons.menu', 'Give') }</NavItem>
        </Fragment>
        }
        {this.props.config.hasGlobalPosts &&
          <LinkContainer to={'/'} activeClassName=''>
            <NavItem className='menuItem' eventKey={1}>NewsFeed</NavItem>
          </LinkContainer>
        }
        {!this.props.config.disableSubscribe && !this.props.config.stringValues.site.header?.hideSubscribeButton &&
          <LinkContainer to={`/subscribe${getUrlParams()}`} activeClassName=''>
            <NavItem className='menuItem' eventKey={2} id='subscribeLink'>
              { get(this.props.config.stringValues, 'site.buttons.subscribe', 'Sign Up') }
          </NavItem>
          </LinkContainer>
        }
      </Nav>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  config: state.config
})

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu)
